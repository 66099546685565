.bio{
    font-size: 0.9rem;
    color: #aaa;
}

.forgot{
    font-size: 0.8rem;
    color: #aaa;
}

.forgotSpan{
    color: #7BC50E;
}