$gray-100: rgb(242, 248, 250) !default;
$gray-200: #eff8fb !default;

$dark: #333333;
$cyan: #03a6cc;

$primary: $cyan;
$secondary: $dark;

$link-color: $secondary;

$font-family-sans-serif: "Manrope", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
$headings-font-family: "Montserrat Alternates", -apple-system,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

$h1-font-size: 1.5rem;
$h2-font-size: 1rem;
$h3-font-size: 1rem;

$card-border-radius: 1.2rem;

$btn-padding-y: 0.5rem;
$btn-padding-x: 2rem;

$btn-border-radius: 1.5rem;
$btn-border-radius-lg: 1.5rem;
$btn-border-radius-sm: 1.5rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 960px,
) !default;

$border-radius-lg: 0.4rem;

$display1-size: 3rem !default;
$display2-size: 2rem !default;
$display3-size: 1.6rem !default;
$display4-size: 1.3rem !default;

$border-color: #cedde2 !default;
