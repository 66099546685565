header {
  background: #f2f8fa;

  &.page-header {
    margin-top: calc(-40px - 2rem);
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0.1s;

    &.retract {
      transform: translateY(-100%);
      transition: transform 0.5s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s;
    }
  }

  &.sticky-page-header {
    z-index: 2;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: transform 0.3s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0.5s;

    &:not(.show) {
      transform: translateY(-100%);
      transition: transform 0.1s cubic-bezier(0.45, 0.05, 0.55, 0.95) 0s;
    }

    .container {
      min-height: calc(40px + 2rem);
    }
  }
}
