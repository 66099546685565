.bio{
    composes: bio from '../forgot/ForgotPasswordForm.module.scss';
}

.forgot{
    composes: forgot from '../forgot/ForgotPasswordForm.module.scss';
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.forgotSpan{
    composes: forgotSpan from '../forgot/ForgotPasswordForm.module.scss';
}