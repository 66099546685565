@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500&family=Montserrat+Alternates:wght@400;500;600&display=swap");

@import "./variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "reset";
@import "typography";
@import "kanban";
@import "auth";
@import "page-header";
@import "taking-survey";
@import "surveys";
@import "reports";
@import "users";
@import "permissions";

body,
main {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

main {
  display: flex;

  > * {
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  nav#primary {
    flex: 0 0 280px;
    width: 280px;
    background: white;
    transition: width 0.2s cubic-bezier(0.2, 0.2, 0, 1),
      flex 0.2s cubic-bezier(0.2, 0.2, 0, 1), box-shadow 0.2s ease;
    white-space: nowrap;

    @include media-breakpoint-down(md) {
      position: absolute;
      z-index: 10;
      height: 100vh;
      box-shadow: $box-shadow-lg;

      @include media-breakpoint-up(sm) {
        &:not(.expanded) {
          display: block;
          width: calc(2em + 40px);
          flex: 0 0 calc(2em + 40px);
          box-shadow: none;

          .item-description,
          .menu-logo-extended {
            display: none;
          }

          h2 {
            opacity: 0;
            width: 0px !important;
          }

          section {
            margin-bottom: 0;
          }
        }
      }
    }

    h2 {
      transition: opacity 0.2s ease;
      padding: 0 1em 0 1.46875em;
      font-size: 0.9em;
    }

    section {
      transition: margin-bottom 0.3s ease;
      margin-bottom: 2em;

      a div > svg {
        margin-left: 0.46875em;
      }

      a.active {
        color: #03a6cc;
        border-right: 0.2rem solid #03a6cc;

        #mask-icon rect,
        #icon {
          fill: #03a6cc;
        }
      }

      .item {
        display: flex;
        align-items: center;

        & > span {
          padding-left: 0.8rem;
        }
      }
    }

    &.nav-login,
    &.nav-login-password-forgot {
      display: none;
    }
  }

  section#page {
    flex-grow: 1;
    background: $gray-100;
    border-top-left-radius: $border-radius-lg;
    border-bottom-left-radius: $border-radius-lg;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.17);
    position: relative;
    // border-left: #dae9ec 1px solid;

    @include media-breakpoint-up(sm) {
      margin-left: calc(2em + 40px);
    }

    @include media-breakpoint-up(lg) {
      margin-left: 0 !important;
    }

    .page-section {
      background: white;
      border: 1px solid $border-color;
      padding: $grid-gutter-width / 2;
      margin-bottom: 2em;
    }

    .secondary-menu {
      .nav-item {
        margin: 0 1.5em 0 0;

        .nav-link {
          padding: 1em 0;
        }

        &.active {
          .nav-link {
            padding-bottom: 0.8em;
            color: $primary;
            border-bottom: 0.2rem solid $primary;
          }
        }
      }
    }
  }
}
