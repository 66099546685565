.auth-box {
  background: #f2f8fa;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 2em;
  display: flex;
  align-items: center;

  & h2 {
    padding-top: 2rem;
    padding-left: 2rem;
  }

  .auth-form-wrapper {
    width: 100%;
    max-width: 300px;

    .password-field-wrapper {
      position: relative;
      .toggle-password-visibility {
        background: transparent;
        border: none;
        right: 0;
        top: 2.6em;
        position: absolute;
      }
    }
  }
}
