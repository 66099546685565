.survey-box {
  overflow: hidden;
  > .row {
    min-height: 300px;
  }
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .question-count {
    position: absolute;
    top: $grid-gutter-width;
    right: $grid-gutter-width;
  }

  .spinner-border {
    color: white !important;
  }

  &.survey-box--STARTUP {
    background: radial-gradient(
      ellipse at center,
      rgb(59, 181, 206) 0%,
      rgb(15, 141, 163) 100%
    ) !important;
  }
  &.survey-box--TEAM {
    background: radial-gradient(
      ellipse at center,
      #179d98 0%,
      rgb(12, 116, 112) 100%
    ) !important;
  }
}

.takeSurveyCheck{
  display: block;
  position: relative;
  margin-left: -2rem;

  label {
    width: 100%;
    padding: 1rem;
    padding-left: 3rem;
    border-radius:  1.2rem;
    text-align: left;
    cursor: pointer;
    background-color: #E3F3F2;
  }
  
  &:after {
    width: 32px;
    height: 32px;
    content: '';
    background-image: url("../assets/square.svg");
    background-repeat: no-repeat;
    background-position: 2px 3px;

    z-index: 2;
    position: absolute;
    left: 35px;
    top: 56%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  input:checked ~ label {
    background-color: #C7F2EF;

    &:after {
      width: 32px;
      height: 32px;
      content: '';

      background-image: url("../assets/check-square.svg");
      background-repeat: no-repeat;
      background-position: 2px 3px;
  
      z-index: 2;
      position: absolute;
      left: 35px;
      top: 56%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    
  }
  
  input {
    z-index: 2;
    position: absolute;
    cursor: pointer;
    visibility: hidden;
  }
}

.takeSurveyRadio{
  display: block;
  position: relative;
  margin-left: -2rem;

  label {
    padding: 1rem;
    padding-left: 3rem;
    border-radius:  1.2rem;
    text-align: left;
    cursor: pointer;
    background-color: #E3F3F2;
  }
  
  &:after {
    width: 32px;
    height: 32px;
    content: '';
    background-image: url("../assets/circle.svg");
    background-repeat: no-repeat;
    background-position: 2px 3px;

    z-index: 2;
    position: absolute;
    left: 35px;
    top: 56%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  input:checked ~ label {
    background-color: #C7F2EF;

    &:after {
      width: 32px;
      height: 32px;
      content: '';

      background-image: url("../assets/check-circle.svg");
      background-repeat: no-repeat;
      background-position: 2px 3px;
  
      z-index: 2;
      position: absolute;
      left: 35px;
      top: 56%;
      transform: translateY(-50%);
      cursor: pointer;
    }
    
  }
  
  input {
    z-index: 2;
    position: absolute;
    cursor: pointer;
    visibility: hidden;
  }
}