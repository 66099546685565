#page.page-kanban {
  .kanban-row {
    @extend .row;

    @include media-breakpoint-up(md) {
      margin: 0 -3em;
      padding: 0 3em;
    }

    overflow-x: auto;

    .react-kanban-board {
      overflow-y: visible !important;
    }
  }

  .intro-wrapper {
    transition: max-height 0.2s ease, padding 0.2s ease, margin 0.2s ease;
    max-height: 500px;

    &.hide-intro {
      overflow: hidden;
      max-height: 0;
    }
  }

  .kanban-card {
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    border: 1px solid #f0f7f9;
    border-radius: 0.4em;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    background: white;
    position: relative;
    overflow: hidden;

    p {
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      &:not(:hover) {
        button.edit {
          display: none;
        }
      }
    }

    button.edit {
      position: absolute;
      top: 1em;
      right: 1em;

      img {
        height: 15px;
        padding-bottom: 4px;
      }
    }
  }

  .react-kanban-column {
    background-color: #ffffff91;
    border: 1px solid #cedde2;
    border-radius: 0.8em;
    width: 24rem;
    margin-right: 0.8em;
    position: relative;
  }

  .react-kanban-card {
    background-color: rgba(0, 160, 153, 0.1);
    border-radius: 1.2rem;
    padding: 1rem;
    width: 22rem;
    max-width: 45rem;
  }

  .react-kanban-card__description {
    overflow-y: scroll;
    max-width: 30rem;
    padding-bottom: 2rem;
  }

  // .react-kanban-card-adder-button {
  //   width: 1.5em;
  //   height: 1.5em;
  //   border: 1px solid #f0f7f9;
  //   color: #03a6cc;
  //   padding: 0;
  //   line-height: 0.3em;
  //   font-size: 1.7em;
  //   border-radius: 0.4em;
  //   position: absolute;
  //   top: 15px;
  //   right: 15px;
  //   margin: 0;
  // }

  .react-kanban-card-adder-form__title,
  .react-kanban-card-adder-form__description {
    background: linear-gradient(
        to right bottom,
        #03a6cc,
        #03a6cc,
        #03a6cc,
        #7bc50e,
        #7bc50e,
        #7bc50e
      )
      bottom no-repeat;
    background-size: 100% 0.1rem;
    border: none;

    padding-bottom: 0.2rem;
    margin-bottom: 1.2rem;
    width: 20rem !important;
  }

  .react-kanban-card__title {
    border-bottom: 1px solid rgba(0, 160, 153, 0.25);
  }

  .react-kanban-column-header {
    padding-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.85em;
    color: #75919c;
  }

  .react-kanban-column div[role="button"] > div {
    display: block !important;
  }
}
