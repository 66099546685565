
.header{
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
}

.bio{
    width: 15rem;
}

.btonBlock{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 50%;
}

.btonBlockMore{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: 50%;
}

.bton{
    color: white;
    border-color: white;
    background-color: transparent;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    border-radius: 1.2rem;
}

.bton:hover{
    color: black;
    background-color: white;
}