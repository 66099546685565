section#page.page-reports-growth {
  table.table {
    th {
      &:first-child {
        border-radius: $border-radius-lg 0 0 0;
      }

      &:last-child {
        border-radius: 0 $border-radius-lg 0 0;
      }
    }

    tbody.accordion {
      tr[role="button"] {
        background: #f5fbfb;
        cursor: pointer;
      }
    }

    .table-row-collapse {
      &.show {
        display: table-row;
      }

      .show-on-change {
        visibility: hidden;
      }

      &.collapsing {
        .show-on-change {
          visibility: visible;
        }
        .hide-on-change {
          display: none;
        }
      }
    }
  }
}
