.level {
  flex: 0 0 220px;
  width: 220px;
  display: block;
  outline: 0 !important;

  &.level-custom {
    flex: 0 0 150px;
    width: 150px;
  }
}

.spacer {
  flex: 0 0 30px;
}

.section-container {
  .section-title {
    width: 6em;
    font-size: 1.2em;
  }
}
