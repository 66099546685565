@import url("https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@400;500;600&display=swap");

h3,
.h3 {
  font-weight: 500;
}

table.table th {
  font-family: $headings-font-family;
}
