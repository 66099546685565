// // containers
// .container-fluid {
//     background-color: rgba(0, 165, 204, 0.08);
//     border-radius: 1.2rem;
//     box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.17);
//     height: 100vh;
// }

.formContainer {
  width: 18rem;
  padding: 2rem;
}

// container elements - rows - cols
.upperRowTop {
  margin-top: 4rem;
}

.rowGaps {
  margin-top: 2rem;
}

.rowGapSelects {
  margin-top: 1rem;
}

.rowError {
  color: red;
  margin: 2rem;
  padding-left: 2rem;
}

//form & inputs
.formBox {
  display: flex;
  align-items: center;

  height: 100vh;
  padding: 2rem;

  & h2 {
    padding-top: 2rem;
    padding-left: 2rem;
  }

  & img {
    margin-left: 2rem;
    margin-top: 2rem;
  }

  & button {
    border-radius: 1.5rem;
    width: 100%;
  }
}

.formInput {
  background: linear-gradient(
      to right bottom,
      #03a6cc,
      #03a6cc,
      #03a6cc,
      #7bc50e,
      #7bc50e,
      #7bc50e
    )
    bottom no-repeat;
  background-size: 100% 0.1rem;
  border: none;

  padding-bottom: 0.2rem;
  margin-bottom: 1.2rem;
  width: 100%;
}

.checkboxInput {
  & input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    height: auto;
    width: auto;
    border: 0;
    overflow: hidden;
  }

  & label {
    display: inline-block;
    background-color: white;
    color: #333333;

    padding: 1rem;
    width: 15rem;

    border-radius: 1.2rem;
    border: 0.05rem solid #333333;
    margin-right: 1rem;
  }

  & input:checked + label {
    background-color: #03a6cc;
    color: white;
    border: 0.05rem solid #03a6cc;
  }
}

.selectInput {
  & div {
    border-radius: 1.2rem;
    padding-left: 0.3rem;
  }
}

.textInput {
  border-radius: 1.2rem;
  padding: 1rem;
  width: 100%;
  min-height: 10rem;
}

// cards & card-items
.cardLegend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 0.5rem;
  padding: 1rem;
  background-color: rgba(0, 160, 153, 0.1);
  border-radius: 1.2rem;

  & h3 {
    margin-bottom: 0rem !important;
  }
}

.cardLegendItem {
  display: flex;
  flex-direction: row;
  align-items: center;

  & span {
    margin-left: 0.5rem;
    font-size: 0.9rem;
  }
}

.cardLegendItemColor {
  padding: 0.8rem;
  background-color: #00a099;
  border-radius: 0.5rem;
}

.cardDataContainer {
  height: 30.7rem;
  overflow: scroll;
}

.cardDataContainerItems {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.1rem solid rgba(0, 160, 153, 0.1);

  display: flex;
  justify-content: center;
  align-items: center;

  & p {
    margin-bottom: 0rem !important;
  }

  & label {
    margin-bottom: 0rem !important;
  }
}

// buttons
.secondaryButton {
  text-decoration: underline;
  border: none;
  background-color: transparent;
}

.disabledButton {
  pointer-events: none;
  color: #aaa;
}

.compareButton {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2rem;
}

// empty state
.empty {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.empty_img {
  margin-top: 2rem;
  align-self: center;
  width: 40%;
}

/*refactor*/

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: saturate(180%) blur(5px);
  justify-content: center;
  align-items: center;
  display: flex;

  .modal-dialog {
    width: 100%;
    max-height: 100vh;

    .modal-content {
      box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.1);
      border: none;

      .modal-header,
      .modal-footer {
        border-color: #eee;
      }

      .modal-body {
        padding-top: 2em;
        padding-bottom: 2em;
        overflow-y: auto;
      }

      .modal-title {
        font-size: 1.2em;
      }
    }
  }
}

.container-fluid,
.container {
  @include media-breakpoint-up(md) {
    padding-left: 3em;
    padding-right: 3em;
  }
}

.cursor-default {
  cursor: default;
}

table.table {
  th {
    background: #eaf5f5;
    border: none;

    &:first-child {
      border-radius: $border-radius-lg 0 0 $border-radius-lg;
    }

    &:last-child {
      border-radius: 0 $border-radius-lg $border-radius-lg 0;
    }
  }
  tbody {
    tr:first-child {
      td {
        border-top: none;
      }
    }
  }
}

.table-responsive {
  @include media-breakpoint-up(lg) {
    overflow: visible !important;
  }
}

.recharts-surface {
  overflow: visible;
}
